<template>
  <div class="register">
    <div class="container">
      <div class="register-info">
        <h5 class="register__title">
          Регистрируйся и выигрывай вместе с <b>Smart-Cashback</b>
        </h5>
        <a href="#" class="register__button">Зарегистрироваться в конкурсе</a>
      </div>
      <img
        src="@/assets/img/register.png"
        alt="Регистрируйся и выигрывай вместе с Smart-Cashback"
        class="register__img"
      />
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="sass" scoped>
.register
  padding: 32px 0
  margin: 50px 0 100px
  background-color: #29BE8C

  @media (max-width: 800px)
    margin-bottom: 30px

  .container
    display: flex
    align-items: center
    gap: 50px
    justify-content: space-between

  &-info
    width: 100%
    flex: auto

  &__title
    color: #EAEBEC
    font-size: 72px
    font-weight: 400
    margin-bottom: 32px

    @media (max-width: 800px)
      font-size: 40px

  &__button
    border: none
    outline: none
    cursor: pointer
    padding: 16px 32px
    font-size: 24px
    color: #ffffff
    border-radius: 14px
    background-color: #0068FF
    transition: 0.3s
    text-decoration: none

    @media (max-width: 800px)
      font-size: 16px
      padding: 12px
      border-radius: 8px

    &:hover
      background-color: #ffffff
      color: #0068FF

  &__img
    width: 100%
    flex: auto
    height: auto
    border-radius: 30px
    border: 10px solid #0068FF
    overflow: hidden

    @media (max-width: 800px)
      display: none
</style>
