<template>
  <div class="stat" id="stat">
    <div class="container">
      <div class="stat-left">
        <p class="stat__plug">Статистика</p>
        <h5 class="stat__title">Отчитываемся о ходе розыгрыша:)</h5>
        <ul v-if="statistic.length" class="stat-list">
          <li
            v-for="(item, index) in statistic"
            :key="index"
            class="stat-list__item"
          >
            <StatisticListIcon class="stat-list__item--icon" />
            <component :is="index === statistic.length - 1 ? 'b' : 'p'">
              {{ item }}
            </component>
          </li>
        </ul>
      </div>
      <div class="stat-right">
        <StatisticGraphicFirst class="stat-right__graph" />
        <StatisticGraphicSecond class="stat-right__graph" />
        <StatisticGraphicThird class="stat-right__graph" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import StatisticListIcon from "@/components/home/statistic/StatisticListIcon.vue";
import StatisticGraphicFirst from "@/components/home/statistic/StatisticGraphicFirst.vue";
import StatisticGraphicSecond from "@/components/home/statistic/StatisticGraphicSecond.vue";
import StatisticGraphicThird from "@/components/home/statistic/StatisticGraphicThird.vue";

const statistic: string[] = [
  "Всего участников: 293 347",
  "Всего победителей: 90 923",
  "Призовой фонд: 2 000 000₽",
  "Уже разыграли: 190 234₽",
  "Нам не хватает только тебя!",
];
</script>

<style lang="sass" scoped>
.stat
  margin: 50px 0
  padding: 40px 0
  background-color: #198E67
  color: #ffffff

  .container
    display: flex
    align-items: center
    gap: 30px

    @media (max-width: 800px)
      flex-direction: column-reverse

  &__plug
    font-size: 24px
    font-weight: 500
    color: #ffffff
    padding: 6px 16px
    border-radius: 8px
    background-color: #EDCB50
    margin-bottom: 14px

  &__title
    font-size: 48px
    font-weight: bold
    margin-bottom: 90px

    @media (max-width: 800px)
      font-size: 30px
      margin-bottom: 30px

  &-list
    display: flex
    flex-direction: column
    gap: 16px

    &__item
      display: flex
      align-items: center
      gap: 16px
      font-size: 24px

  &-left
    width: 100%
    flex: auto
    display: flex
    flex-direction: column
    align-items: flex-start

  &-right
    width: 100%
    flex: auto
    display: grid
    grid-template-columns: repeat(6, 1fr)
    grid-gap: 24px

    @media (max-width: 800px)
      display: flex
      align-items: stretch
      gap: 12px

    &__graph
      width: 100%
      height: auto

      &:nth-child(1)
        grid-column: 1 / 4
      &:nth-child(2)
        grid-column: 4 / 7
      &:nth-child(3)
        grid-column: 3 / 6
        grid-row: 2 / 3
</style>
