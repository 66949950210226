import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeBanner = _resolveComponent("HomeBanner")!
  const _component_HomeSteps = _resolveComponent("HomeSteps")!
  const _component_HomeShops = _resolveComponent("HomeShops")!
  const _component_HomeAbout = _resolveComponent("HomeAbout")!
  const _component_HomeSupport = _resolveComponent("HomeSupport")!
  const _component_HomeWinners = _resolveComponent("HomeWinners")!
  const _component_HomeStat = _resolveComponent("HomeStat")!
  const _component_HomeReviews = _resolveComponent("HomeReviews")!
  const _component_HomeRegister = _resolveComponent("HomeRegister")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HomeBanner),
    _createVNode(_component_HomeSteps),
    _createVNode(_component_HomeShops),
    _createVNode(_component_HomeAbout),
    _createVNode(_component_HomeSupport),
    _createVNode(_component_HomeWinners),
    _createVNode(_component_HomeStat),
    _createVNode(_component_HomeReviews),
    _createVNode(_component_HomeRegister)
  ]))
}