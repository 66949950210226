<template>
  <div class="support">
    <div class="container">
      <div class="support-wrapper">
        <p class="support__title">Остались вопросы? Мы на связи 24/7</p>
        <a :href="`mailto:${email}`" target="_blank" class="support__button">
          Написать в поддержку
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const email: string = process.env.VUE_APP_SUPPORT_EMAIL;
</script>

<style lang="sass" scoped>
.support
  margin-top: -70px
  margin-bottom: 70px

  .container
    display: flex
    flex-direction: column
    align-items: center

  &-wrapper
    width: 100%
    max-width: 900px
    background-color: #FFFFFF
    border-radius: 100px
    padding: 20px 30px 40px 30px
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.25)
    display: flex
    flex-direction: column
    align-items: center
    gap: 20px

    @media (max-width: 800px)
      padding: 20px
      border-radius: 20px

  &__title
    font-size: 46px
    font-weight: bold
    text-align: center

    @media (max-width: 800px)
      font-size: 20px

  &__button
    outline: none
    background-color: #25E2B4
    border: none
    cursor: pointer
    padding: 30px 40px
    border-radius: 100px
    font-size: 20px
    font-weight: bold
    text-decoration: none
    transition: 0.3s
    color: #293241

    @media (max-width: 800px)
      font-size: 16px
      padding: 10px 20px

    &:hover
      opacity: 0.5
</style>
