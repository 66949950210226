<template>
  <div class="about">
    <div class="container">
      <h5 class="about__title">А что кроме денег?</h5>
      <div class="about-row">
        <div v-for="(title, index) in gifts" :key="index" class="about__item">
          <img
            :src="require(`@/assets/img/about/${index + 1}.png`)"
            :alt="title"
            class="about__item--picture"
          />
          <p class="about__item--title">{{ title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const gifts: string[] = ["1 000 PowerBank", "10 iPhone 14", "100 AirPods 3"];
</script>

<style lang="sass" scoped>
.about
  padding: 40px 0 90px
  background-color: #25E2B4

  .container
    display: flex
    flex-direction: column
    align-items: center

  &__title
    text-align: center
    font-size: 70px
    font-weight: bold

    @media (max-width: 800px)
      font-size: 40px

  &__description
    font-size: 50px
    text-align: center
    margin: 30px 0

    @media (max-width: 800px)
      font-size: 30px
      margin: 10px 0

  &-row
    width: 100%
    margin: 30px 0
    display: flex
    align-items: stretch
    justify-content: space-between
    gap: 30px

    @media (max-width: 850px)
      flex-direction: column

      &.money
        display: none

  &__item
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-end
    height: 100%
    gap: 20px
    width: 100%
    flex: auto

    &--picture
      height: 240px
      width: auto

      @media (max-width: 800px)
        height: 150px

    &--title
      font-size: 38px
      font-weight: bold
      text-align: center

      @media (max-width: 800px)
        font-size: 20px
</style>
