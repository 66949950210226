<template>
  <div class="winners" id="winners">
    <div class="container">
      <div class="winners-list">
        <div v-for="(winner, index) in winners" :key="index" class="winner">
          <img
            :src="require(`@/assets/img/winners/${index + 1}.png`)"
            :alt="winner.name"
            class="winner__avatar"
          />
          <p class="winner__name">{{ winner.name }}</p>
          <p class="winner__amount" :style="`color: ${winner.color};`">
            {{ winner.amount }}
          </p>
        </div>
      </div>
      <div class="winners-info">
        <h5 class="winners__title">Топ победителей недели</h5>
        <p class="winners__description">
          Люди которые не постеснялись похвастаться своим выигрышем:) Среди них
          легко можешь оказаться и ты, просто регистрируйся!
        </p>
        <a href="#" class="winners__button">Принять участие</a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
interface WinnerItem {
  readonly name: string;
  readonly amount: string;
  readonly color: string;
}

const winners: WinnerItem[] = [
  {
    name: "Алена Шилова",
    amount: "+143 000 ₽",
    color: "#05E298",
  },
  {
    name: "Александра Петрова",
    amount: "+48 900 ₽",
    color: "#459BFF",
  },
  {
    name: "Влад Трефилов",
    amount: "+7 200 ₽",
    color: "#EDCB50",
  },
];
</script>

<style lang="sass" scoped>
.winner
  display: flex
  align-items: center
  gap: 12px
  padding: 24px 32px
  background-color: #1E2746
  color: #ffffff
  width: 100%
  max-width: 500px
  border-radius: 1000px

  @media (max-width: 800px)
    padding: 10px
    gap: 8px

  &:nth-child(2n)
    margin-left: auto

  &__avatar
    width: 60px
    min-width: 60px
    height: 60px
    border-radius: 50%
    overflow: hidden
    object-fit: cover

    @media (max-width: 800px)
      width: 30px
      min-width: 30px
      height: 30px

  &__name
    font-size: 24px
    font-weight: bold
    color: #ffffff

    @media (max-width: 800px)
      font-size: 14px
      flex: auto

  &__amount
    margin-left: auto
    font-size: 18px
    font-weight: 600
    word-break: keep-all
    white-space: nowrap

    @media (max-width: 800px)
      font-size: 12px

.winners
  margin: 50px 0

  .container
    position: relative
    padding-top: 70px
    padding-bottom: 70px
    display: flex
    align-items: center
    justify-content: space-between
    gap: 40px

    @media (max-width: 800px)
      flex-direction: column

    &:before
      content: ''
      position: absolute
      top: 50%
      left: 50%
      width: calc(100% + 250px)
      border-radius: 1000px
      max-width: calc(100vw - 40px)
      height: 100%
      transform: translate(-50%, -50%)
      box-shadow: 0 4px 4px 0 rgba(0,0,0,0.25)
      z-index: -1
      background-color: #D9D9D9

      @media (max-width: 800px)
        border-radius: 0
        box-shadow: none
        width: 100%
        max-width: 100%

  &-list
    width: 100%
    flex: auto
    display: flex
    flex-direction: column
    gap: 24px

    @media (max-width: 800px)
      gap: 8px

  &-info
    width: 100%
    flex: auto
    display: flex
    flex-direction: column

  &__title
    font-size: 48px
    font-weight: bold
    margin-bottom: 30px

    @media (max-width: 800px)
      text-align: center
      font-size: 30px
      margin-bottom: 15px

  &__description
    font-size: 24px
    color: #7F848D
    margin-bottom: 60px

    @media (max-width: 800px)
      text-align: center
      font-size: 20px
      margin-bottom: 30px

  &__button
    outline: none
    border: none
    padding: 16px 32px
    background-color: #ffffff
    cursor: pointer
    border-radius: 14px
    font-size: 32px
    margin: 0 auto
    transition: 0.3s
    text-decoration: none
    color: #293241

    @media (max-width: 800px)
      font-size: 20px
      padding: 12px 20px

    &:hover
      background-color: #25E2B4
      color: #ffffff
</style>
