<template>
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 100C12.5 123.206 21.7187 145.462 38.1282 161.872C54.5376 178.281 76.7936 187.5 100 187.5C123.206 187.5 145.462 178.281 161.872 161.872C178.281 145.462 187.5 123.206 187.5 100C187.5 76.7936 178.281 54.5376 161.872 38.1282C145.462 21.7187 123.206 12.5 100 12.5C76.7936 12.5 54.5376 21.7187 38.1282 38.1282C21.7187 54.5376 12.5 76.7936 12.5 100Z"
      fill="#4CAF50"
    />
    <path
      d="M144.167 60.8334L87.5 117.5L64.1667 94.1667L52.5 105.833L87.5 140.833L155.833 72.5L144.167 60.8334Z"
      fill="#CCFF90"
    />
  </svg>
</template>
