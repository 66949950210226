<template>
  <div class="banner">
    <div v-if="popupOpened" class="popup">
      <div class="popup-wrapper">
        <h4 class="popup__title">Благодарим за регистрацию!</h4>
        <SuccessIcon class="popup__icon" />
        <p class="popup__description">
          Теперь Вы стали участником проекта <i>Smart-Cashback</i>
        </p>
        <button class="popup__button" @click="closePopup">Продолжить</button>
      </div>
      <div class="popup__overlay" @click="closePopup"></div>
    </div>
    <div class="container">
      <h3 class="banner__title">Участвуй в честном розыгрыше!</h3>
      <p class="banner__description">
        Регистрируйся по номеру телефона и получи гарантированный приз
      </p>
      <div class="banner__input-wrapper">
        <input
          class="banner__input"
          v-model="phone"
          placeholder="Введите ваш номер телефона"
        />
        <button
          class="banner__input--button"
          @click="openPopup"
          :disabled="phone.length !== 18 || !policyAgree || popupWasShowed"
        >
          Участвовать
        </button>
      </div>
      <div class="banner__policy">
        <div class="banner__checkbox-wrapper">
          <input
            type="checkbox"
            class="banner__checkbox"
            :class="{ active: policyAgree }"
            v-model="policyAgree"
          />
          <CheckboxIcon class="banner__checkbox--icon" />
        </div>
        <p class="banner__policy--text">
          Я принимаю условия
          <a :href="aggreements" target="_blank"
            >пользовательского соглашения</a
          >
          , <a :href="policy" target="_blank">политики конфиденциальности</a> и
          даю согласие на сохранение свои данных
        </p>
      </div>
      <p class="banner__gift">Призовой фонд уже более<br /><b>2 000 000₽</b></p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Ref, ref, watch } from "vue";
import CheckboxIcon from "@/components/icons/CheckboxIcon.vue";
import SuccessIcon from "@/components/icons/SuccessIcon.vue";

const policy: string = process.env.VUE_APP_POLICY_LINK;
const aggreements: string = process.env.VUE_APP_AGGREEMENTS_LINK;

const phone: Ref<string> = ref("");
const policyAgree: Ref<boolean> = ref(false);
const popupOpened: Ref<boolean> = ref(false);
const popupWasShowed: Ref<boolean> = ref(false);

function openPopup() {
  popupOpened.value = true;
  popupWasShowed.value = true;
}

const closePopup = () => (popupOpened.value = false);

const prefixNumber = (str: string) => {
  if (str === "7") {
    return "7 (";
  }
  if (str === "8") {
    return "8 (";
  }
  if (str === "9") {
    return "7 (9";
  }
  return "7 (";
};

watch(phone, () => {
  const value = phone.value.replace(/\D+/g, "");
  const numberLength = 11;

  let result: string;
  if (phone.value.includes("+8") || phone.value[0] === "8") {
    result = "";
  } else {
    result = "+";
  }

  //
  for (let i = 0; i < value.length && i < numberLength; i++) {
    switch (i) {
      case 0:
        result += prefixNumber(value[i]);
        continue;
      case 4:
        result += ") ";
        break;
      case 7:
        result += "-";
        break;
      case 9:
        result += "-";
        break;
      default:
        break;
    }
    result += value[i];
  }
  //
  phone.value = result;
});
</script>

<style lang="sass" scoped>
.banner
  width: 100%
  background-image: url("@/assets/img/banner_bg.png")
  background-size: cover
  background-position: bottom center
  background-repeat: no-repeat
  padding: 190px 0 200px

  .container
    display: flex
    flex-direction: column
    align-items: center
    text-align: center
    color: #fff

  &__title
    font-size: 58px
    font-weight: bold
    margin-bottom: 45px

    @media (max-width: 800px)
      font-size: 40px
      margin-bottom: 20px

  &__description
    font-size: 48px
    margin-bottom: 45px

    @media (max-width: 800px)
      font-size: 20px

  &__gift
    font-size: 60px
    margin-top: 65px

    @media (max-width: 800px)
      font-size: 30px

  &__input
    background: none
    outline: none
    border: 1px solid #ffffff
    border-radius: 1000px
    font-size: 24px
    padding: 26px 200px 26px 38px
    width: 100%
    color: #ffffff

    @media (max-width: 800px)
      padding: 12px 100px 12px 20px
      font-size: 16px

      &::placeholder
        font-size: 13px

    &--button
      position: absolute
      top: 0
      right: 0
      height: 100%
      width: 200px
      border-radius: 1000px
      border: none
      outline: none
      cursor: pointer
      font-size: 20px
      font-weight: bold
      transition: 0.3s
      background-color: #25E2B4
      color: #293241

      @media (max-width: 800px)
        width: 100px
        font-size: 14px

      &:disabled
        opacity: 1
        background-color: #ffffff
        cursor: not-allowed

    &-wrapper
      width: 650px
      max-width: 100%
      position: relative

  &__policy
    display: flex
    align-items: center
    gap: 15px
    margin-top: 22px
    max-width: 650px

    &--text
      font-size: 20px
      text-align: left

      @media (max-width: 800px)
        font-size: 14px

      a
        color: #ffffff

  &__checkbox
    width: 100%
    height: 100%
    position: absolute
    top: 0
    left: 0
    z-index: 1
    opacity: 0
    cursor: pointer

    &--icon
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      opacity: 0
      transition: 0.3s
      fill: #25E5B7
      width: 100%
      height: auto

    &.active + &--icon
      opacity: 1

    &-wrapper
      position: relative
      width: 27px
      height: 27px
      min-width: 27px
      border: 2px solid #ffffff

      @media (max-width: 800px)
        width: 20px
        height: 20px
        min-width: 20px
</style>
