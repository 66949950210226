<template>
  <div class="home">
    <HomeBanner />
    <HomeSteps />
    <HomeShops />
    <HomeAbout />
    <HomeSupport />
    <HomeWinners />
    <HomeStat />
    <HomeReviews />
    <HomeRegister />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import HomeBanner from "@/components/home/HomeBanner.vue";
import HomeSteps from "@/components/home/HomeSteps.vue";
import HomeAbout from "@/components/home/HomeAbout.vue";
import HomeSupport from "@/components/home/HomeSupport.vue";
import HomeWinners from "@/components/home/HomeWinners.vue";
import HomeStat from "@/components/home/HomeStat.vue";
import HomeReviews from "@/components/home/HomeReviews.vue";
import HomeRegister from "@/components/home/HomeRegister.vue";
import HomeShops from "@/components/home/HomeShops.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    HomeShops,
    HomeRegister,
    HomeReviews,
    HomeStat,
    HomeWinners,
    HomeSupport,
    HomeAbout,
    HomeSteps,
    HomeBanner,
  },
});
</script>
