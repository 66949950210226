<template>
  <div class="shops">
    <div class="container">
      <h4 class="shops__title">Покупай здесь, чтобы принять участие!</h4>
      <div class="shops-row">
        <a
          href="https://www.ozon.ru/brand/involta-100358362/"
          target="_blank"
          class="shops__image-wrapper"
        >
          <img class="shops__image" src="@/assets/img/shops/ozon.png" alt="" />
        </a>
        <a
          href="https://www.wildberries.ru/brands/involta"
          target="_blank"
          class="shops__image-wrapper"
        >
          <img class="shops__image" src="@/assets/img/shops/wb.png" alt="" />
        </a>
        <a
          href="https://market.yandex.ru/search?suggest_text=involta&vendorId=52666195&rs=eJxNUD0sREEY3EXk3YnklbhcsjrRyJ0XCfckm6skmqMX7xL3qisUVAqbEJEIlU6zaiQUGkesgkJ7UYjCxk-UFER03kwhmslk5vtmvt3ydmdNXkuh0skM3WwlQ_0aQ3lqAdsTGfodKh9w_RF0vQqurqCLTaApnEOpcesNSnIMbs7A_TDdXSp34GYa6GbQqwaZvw7X3YDrU-LieIb2h_wEaD95w8ol-AtcVaI-x4QDJk8hM_nmbTlwvQa0MVwrwcUI79xCWrKBSffFO9vM4RvtHnv5A-aQ-j1nHrDlnrmlqBcvMFPlfAcbe9i-z_l-_s8j2r3hluZvvKPFNyu3MhiNxqJ62oiqcb6QDwIZqr5e1RV2D-QWGml9ubk0X1ZiSPyZUsn_ZglmKH8Bvc-VjA%2C%2C&glfilter=7893318%3A52666195"
          target="_blank"
          class="shops__image-wrapper"
        >
          <img class="shops__image" src="@/assets/img/shops/ym.png" alt="" />
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass">
.shops
  margin: 60px 0 100px

  & .container
    display: flex
    flex-direction: column
    align-items: center

  &__title
    font-size: 60px
    text-align: center
    margin-bottom: 60px

    @media (max-width: 600px)
      font-size: 30px

  &-row
    display: flex
    align-items: stretch
    justify-content: space-between
    gap: 50px
    margin-bottom: 50px
    width: 100%

    @media (max-width: 600px)
      flex-direction: column
      align-items: center

  &__image
    max-width: 100%
    height: auto

    &-wrapper
      max-width: 100%
      width: 300px
</style>
<script setup lang="ts"></script>
