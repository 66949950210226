<template>
  <div class="steps" id="steps">
    <div class="container">
      <h4 class="steps__title">Как это работает?</h4>
      <p class="steps__description">Все супер просто!</p>
    </div>
    <div class="steps-grid-wrapper">
      <div class="steps-grid">
        <div v-for="(step, index) in steps" :key="index" class="steps__item">
          <p class="steps__item--title">{{ index + 1 }}. {{ step.title }}</p>
          <p class="steps__item--description">{{ step.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
interface StepItem {
  readonly title: string;
  readonly description: string;
}

const steps: StepItem[] = [
  {
    title: "Регистрируйся",
    description:
      "Введи свой номер телефона и ты уже участвуешь в розыгрыше денег и других призов!",
  },
  {
    title: "Совершай покупки",
    description: "Все абсолютно просто и понятно.",
  },
  {
    title: "Соблюдай условия",
    description:
      "Не нужно пытаться нас обмануть! Иначе ты ничего не получишь :(",
  },
  {
    title: "Забирай деньги или приз",
    description:
      "По итогам выполнения заданий подведем итоги и объявим победителей!",
  },
];
</script>

<style lang="sass" scoped>
.steps
  padding: 50px 0 70px
  display: flex
  flex-direction: column
  align-items: center

  .container
    display: flex
    flex-direction: column
    align-items: center

  &__title
    font-size: 52px
    font-weight: bold
    margin-bottom: 20px
    text-align: center

  &__description
    font-size: 32px
    color: #7F848D
    margin-bottom: 70px

  &-grid
    background-color: #ffffff
    display: flex
    align-items: stretch
    justify-content: center
    gap: 24px

    @media (max-width: 1300px)
      display: grid
      grid-template-columns: repeat(2, 1fr)

    @media (max-width: 800px)
      grid-template-columns: 1fr

    &-wrapper
      width: 100%
      position: relative
      display: flex
      flex-direction: column
      align-items: center

      &:before
        content: ''
        position: absolute
        top: 50%
        left: 0
        width: 100%
        height: 2px
        transform: translateY(-50%)
        background-color: #1E2746
        opacity: 0.2
        z-index: -1

        @media (max-width: 1300px)
          content: none

  &__item
    padding: 24px
    background-color: #1E4627
    border-radius: 18px
    color: #fff
    width: 300px
    position: relative
    display: flex
    flex-direction: column
    background-image: url("@/assets/img/step__bg.png")
    background-size: contain
    background-position: top left
    background-repeat: no-repeat

    &--title
      font-size: 22px
      font-weight: bold
      margin-bottom: 16px

    &--description
      color: #D4D6D9
      margin-top: auto

    &:nth-child(1),
    &:nth-child(3)
      &:after
        content: ''
        position: absolute
        top: 100%
        left: 50%
        width: calc(100% + 24px)
        height: 40px
        border: solid #1E2746
        border-width: 0 2px 2px 2px
        border-radius: 0 0 16px 16px
        opacity: 0.2

        @media (max-width: 1300px)
          content: none

    &:nth-child(2)
      &:after
        content: ''
        position: absolute
        bottom: 100%
        left: 50%
        width: calc(100% + 24px)
        height: 40px
        border: solid #1E2746
        border-width: 2px 2px 0 2px
        border-radius: 16px 16px 0 0
        opacity: 0.2

        @media (max-width: 1300px)
          content: none
</style>
