<template>
  <div class="reviews" id="reviews">
    <div class="container">
      <h3 class="reviews__title">Отзывы победителей</h3>
      <Swiper
        class="reviews-slider"
        navigation
        pagination
        :slides-per-view="1"
        :space-between="50"
        :modules="modules"
      >
        <SwiperSlide v-for="(review, index) in reviews" :key="index">
          <div class="review">
            <img
              :src="require(`@/assets/img/reviews/${index + 1}.png`)"
              :alt="review.name"
              class="review__image"
            />
            <div class="review-info">
              <p class="review__name">{{ review.name }}</p>
              <p class="review__win">
                Выигрыш: <b>{{ review.win }}</b>
              </p>
              <div class="review__line"></div>
              <p class="review__text">{{ review.text }}</p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script setup lang="ts">
interface ReviewItem {
  readonly name: string;
  readonly win: string;
  readonly text: string;
}

import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const modules = [Pagination, Navigation];

const reviews: ReviewItem[] = [
  {
    name: "Елена Дубова",
    win: "iPhone 14",
    text: `Я не могу поверить, что я выиграла iPhone 14 в розыгрыше! Это просто невероятно! Я так рада и благодарна организаторам за такую возможность.\n\nКогда я узнала о розыгрыше, я решила принять участие, потому что всегда хотела иметь iPhone. Я давно мечтала о нем, но не могла себе позволить такую покупку. И вот, моя мечта сбылась!`,
  },
  {
    name: "Дмитрий Смирнов",
    win: "AirPods 3",
    text: `Я до сих пор не могу поверить, что я стал победителем и выиграл наушники! Это было так неожиданно и приятно:) Я давно мечтал о качественных наушниках, и теперь моя мечта сбылась. Они звучат просто потрясающе, я наслаждаюсь каждой нотой. Большое спасибо Smart-Cashback за такой замечательный подарок!`,
  },
  {
    name: "Мария Котельникова",
    win: "10 900₽",
    text: `До сих пор слабо верится в то, что я выиграла деньги! При таких условиях розыгрыша, было очень круто получить достаточно не маленькую сумму! Организаторам респект!`,
  },
  {
    name: "Сергей Борисов",
    win: "PowerBank",
    text: `Я был очень рад, когда узнал, что выиграл паурбанк. Не айфон конечно, но все равно, спасибо организаторам за возможность выиграть что-то. Я с радостью буду использовать его!`,
  },
  {
    name: "Виктория Денисова",
    win: "900₽",
    text: `Пусть не самый большой выигрыш, но получить почти тысячу рублей просто так - всегда приятно! Еще раз спасибо команде Smart-Cashback! Успехов вам:)`,
  },
];
</script>

<style lang="sass" scoped>
.review
  display: flex
  align-items: center
  gap: 30px
  padding: 32px
  background-color: #F0F9FF
  border: 5px solid #0068FF
  border-radius: 18px
  margin: 0 100px

  @media (max-width: 800px)
    margin: 0 40px
    flex-direction: column
    align-items: flex-start
    gap: 10px
    padding: 10px
    border-width: 3px

  &__image
    width: 30%
    height: auto
    border-radius: 14px

    @media (max-width: 800px)
      width: 50px
      height: 50px

  &__name
    font-size: 24px
    font-weight: bold
    margin-bottom: 20px

    @media (max-width: 800px)
      font-size: 20px
      margin-bottom: 0

  &__win
    color: #7F848D
    font-size: 18px
    margin-bottom: 20px

    @media (max-width: 800px)
      font-size: 14px
      margin-bottom: 5px

  &__line
    width: 190px
    max-width: 100%
    height: 1px
    background-color: #D4D6D9

  &__text
    margin-top: 20px
    font-size: 22px
    font-style: italic
    white-space: pre-wrap

    @media (max-width: 800px)
      margin-top: 5px
      font-size: 16px

.reviews
  margin: 50px 0

  .container
    display: flex
    flex-direction: column
    align-items: center

  &__title
    margin-bottom: 80px
    font-size: 48px
    font-weight: bold
    text-align: center

    @media (max-width: 800px)
      font-size: 30px
      margin-bottom: 30px

  &-slider
    width: 100%
    padding-bottom: 40px
</style>

<style lang="sass">
.reviews-slider
  .swiper-pagination-bullet
    width: 10px
    height: 10px
    background-color: #EAEBEC
    opacity: 1
    transition: 0.3s

    &-active
      background-color: #1C51FD

  .swiper-button-prev
    left: 0

  .swiper-button-next
    right: 0

  .swiper-button-prev,
  .swiper-button-next
    transform: translateY(-15px)
    width: 44px
    height: 44px
    background-color: #1C51FD
    border-radius: 10px
    transition: 0.3s

    @media (max-width: 800px)
      width: 30px
      height: 30px
      border-radius: 5px

    &:not(.swiper-button-disabled):hover
      opacity: 0.5

    &::after
      font-size: 14px
      color: #ffffff
      transition: 0.3s

    &.swiper-button-disabled
      background-color: rgba(22, 129, 254, 0.1)
      opacity: 1

      &::after
        color: #545B67
</style>
