<template>
  <MainHeader :blur="headerBlur" />
  <RouterView />
  <MainFooter />
</template>

<script setup lang="ts">
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import { onBeforeUnmount, onMounted, ref, Ref } from "vue";

const headerBlur: Ref<boolean> = ref(false);

function onScroll(): void {
  headerBlur.value = document.documentElement.scrollTop >= 100;
}

onMounted(() => {
  document.addEventListener("scroll", onScroll);
});

onBeforeUnmount(() => {
  document.removeEventListener("scroll", onScroll);
});
</script>

<style lang="sass">
@import '@/assets/sass/main'
</style>
