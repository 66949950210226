<template>
  <footer class="footer">
    <div class="container">
      <div class="footer-col">
        <img
          src="@/assets/img/logo_dark.png"
          alt="Smart-Cashback"
          class="footer__logo"
        />
        <p class="footer__copy">© 2024 All rights reserved.</p>
      </div>
      <div class="footer-col">
        <a href="#" class="footer__link">Главная</a>
        <a href="#steps" class="footer__link">Правила</a>
        <a href="#winners" class="footer__link">Победители</a>
        <a href="#stat" class="footer__link">Статистика</a>
        <a href="#reviews" class="footer__link">Отзывы</a>
      </div>
      <div class="footer-col end">
        <a :href="policy" target="_blank" class="footer__link">
          Политика конфиденциальности
        </a>
        <a :href="aggreements" target="_blank" class="footer__link">
          Пользовательское соглашение
        </a>
        <a :href="`mailto:${email}`" target="_blank" class="footer__link">
          Поддержка
        </a>
      </div>
      <div class="footer-col center" style="display: none">
        <p class="footer__label">Заходи к нам в телегу:</p>
        <a href="#" target="_blank">
          <TelegramIcon class="footer__tg-icon" />
        </a>
      </div>
    </div>
    <div class="container">
      <p class="footer__info">{{ info }}</p>
    </div>
  </footer>
</template>

<script setup lang="ts">
import TelegramIcon from "@/components/icons/TelegramIcon.vue";
const email: string = process.env.VUE_APP_SUPPORT_EMAIL;
const policy: string = process.env.VUE_APP_POLICY_LINK;
const aggreements: string = process.env.VUE_APP_AGGREEMENTS_LINK;
const info =
  'Название организации ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "СМАРТ ПРОГРАММАТИК" Юридический адрес организации Российская Федерация, 153000, ИВАНОВСКАЯ ОБЛ, Г ИВАНОВО, УЛ ПАРИЖСКОЙ КОММУНЫ, дом 3А, ОФИС 409 ИНН 3702251547';
</script>

<style lang="sass" scoped>
.footer
  margin-top: 70px
  padding: 30px 0

  @media (max-width: 800px)
    margin-top: 30px
    padding: 20px 0

  .container:first-child
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-gap: 20px

    @media (max-width: 800px)
      grid-template-columns: 1fr

  &-col
    display: flex
    flex-direction: column
    align-items: flex-start
    gap: 15px

    @media (max-width: 800px)
      flex-direction: row
      align-items: center
      flex-wrap: wrap

    &.center
      align-items: center

    &.end
      align-items: end

  &__logo
    width: 165px
    height: auto

  &__copy
    font-size: 18px

  &__link
    color: #7F848D
    text-decoration: none
    transition: 0.3s

    &:hover
      color: #0068FF

  &__label
    font-size: 16px
    font-weight: bold

  &__tg-icon
    width: 44px
    height: auto

  &__info
    font-size: 13px
    opacity: 0.5
    margin-top: 40px
</style>
