<template>
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 236.988 236.988"
    xml:space="preserve"
    class="articleAdd__checkbox-icon"
  >
    <polygon
      points="198.098,24.326 87.543,134.881 38.891,86.229 0,125.121 87.543,212.662 236.988,63.217 "
    ></polygon>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
